<template>
  <mobile-screen :header="true" screen-class="icon-app1 data-regions gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="applications-header icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          Data regions
        </div>
        <template v-slot:right>
          <router-link :to="{ name: 'r_add-data-region' }">
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            placeholder="Search"
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
    </template>
    <ul
      v-if="listOfDataRegions && listOfDataRegions.length"
      class="clebex-item-section pill"
    >
      <li
        class="clebex-item-section-item cursor-pointer"
        v-for="dataRegion in listOfDataRegions"
        :key="dataRegion.id"
      >
        <div v-if="selection" class="clebex-item-content-wrapper">
          <div class="checkbox tiny alt block">
            <input
              type="radio"
              :id="`user${dataRegion.id}`"
              name="user"
              :value="dataRegion.id"
              @change="selectedDataRegion = dataRegion.id"
            />
            <label :for="`user${dataRegion.id}`" class="highlight">
              <icon icon="#cx-app1-checkmark" />
              {{ dataRegion.name }}</label
            >
          </div>
        </div>
        <div
          v-else
          @click="editDataRegion(dataRegion.id)"
          class="clebex-item-content-wrapper"
        >
          <span class="label text">
            {{ dataRegion.name }}
          </span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                icon="#cx-app1-arrow-right-12x12"
                width="12"
                height="12"
              ></icon>
            </span>
          </span>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu">
        <ul class="actions-list">
          <li class="action">
            <!-- <button
              class="action-btn"
              :disabled="processing"
              @click="selection = !selection"
            >
              Select
            </button> -->
          </li>
          <li class="action">
            <!-- <button
              class="action-btn"
              :disabled="!selectedDataRegion || processing"
              @click="show = true"
            >
              Delete
            </button> -->
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      v-if="show"
      class="confirm-modal"
      type="success"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :confirm-action="startDelete"
      :show="show"
      @close="show = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("manage-companies.companies.delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("manage-companies.companies.delete-action-text") }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import { mapActions, mapState } from "vuex";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";

export default {
  name: "DataRegions",
  data() {
    return {
      selectedDataRegion: 0,
      processing: false,
      show: false,
      searchQuery: "",
      selection: false
    };
  },
  created() {
    this.getDataRegions();
  },
  computed: {
    ...mapState("dataRegion", ["dataRegions"]),
    listOfDataRegions() {
      const query = this.searchQuery;
      if (
        this.dataRegions &&
        this.dataRegions.data &&
        this.dataRegions.data.length
      ) {
        if (query && query.length > 1) {
          return this.dataRegions.data.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.dataRegions.data;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("dataRegion", [
      "getDataRegions",
      "setSelectedDataRegion",
      "deleteDataRegion"
    ]),
    selectDataRegion(id) {
      this.selectedDataRegion = id;
    },
    editDataRegion(id) {
      this.setSelectedDataRegion(
        this.dataRegions.data.find(item => item.id === id)
      );
      this.$router.push({ name: "r_edit-data-region" });
    },
    startDelete() {
      this.processing = true;
      this.deleteDataRegion(this.selectedDataRegion).finally(() => {
        this.processing = false;
        this.setSelectedDataRegion(null);
        this.$nextTick(() => {
          this.$router.push({ name: "r_data-regions" });
        });
      });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
